import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import './App.scss';
import { DonationForm, DonationFormProps } from './DonationForm';

export const App = ({
  brandColor = '#005792',
  backgroundColor = '#e1e9ef',
  campus_obj,
  page_id,
}: DonationFormProps & { backgroundColor: string }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/embed`}>
        <DonationForm
          page_id={page_id}
          brandColor={brandColor}
          campus_obj={campus_obj}
          embedded
        />
      </Route>
      <Route path={`${path}`}>
        <div className="min-vh-100 d-flex flex-column" style={{ backgroundColor }}>
          <div className="my-auto p-2 p-sm-3 p-md-2">
            <DonationForm page_id={page_id} brandColor={brandColor} campus_obj={campus_obj} />
          </div>
        </div>
      </Route>
    </Switch>
  );
};
