import firebase from 'firebase/app';
import 'firebase/firestore';
import { FIREBASE_DATABASE_URL, FIREBASE_PROJECT_ID, FIREBASE_WEB_API_KEY } from './const';

firebase.initializeApp({
  databaseURL: FIREBASE_DATABASE_URL,
  apiKey: FIREBASE_WEB_API_KEY,
  projectId: FIREBASE_PROJECT_ID,
});
const db = firebase.firestore();
if (process.env.NODE_ENV === 'development') {
  db.settings({
    host: 'localhost:8080',
    ssl: false,
  });
}
export default db;
