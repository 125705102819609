import 'core-js/stable';

import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router, Route, RouteComponentProps, Switch,
} from 'react-router-dom';
import { App } from './App';
import { SENTRY_CLIENT_DSN, STRIPE_PUBLISHABLE_KEY } from './const';
import db from './firebase';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: SENTRY_CLIENT_DSN,
  integrations: [
    new CaptureConsole({
      levels: ['info', 'error', 'debug'],
    }),
  ],
});
class MainApp extends Component<
  RouteComponentProps<{ id: string }>,
  { page: any; stripePromise: Promise<Stripe | null> | null }
> {
  constructor(props: Readonly<RouteComponentProps<{ id: string }>>) {
    super(props);

    this.state = {
      page: null,
      stripePromise: null,
    };
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      const doc = await db.collection('pages').doc(this.props.match.params.id).get();

      if (doc.exists) {
        const standardConnect: { betas:string[], stripeAccount?: string } = { betas: ['acss_debit_beta_2'] };
        if (doc.get('campus_obj.organisation_obj.payment_gateway.slug') === 'stripe') {
          standardConnect.stripeAccount = doc.get(
            'campus_obj.organisation_obj.payment_gateway.gateway_id',
          );
        }

        const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, standardConnect);

        window.addEventListener(
          'message',
          ({ data: { key, payload } }: { data: { key: string; payload: any } }) => {
            if (key === 'Kweeve.PAGE.design') {
              this.setState({ page: { ...this.state.page, ...payload } });
            }
          },
        );
        return this.setState({ page: doc.data(), stripePromise });
      }
    }
  }

  render() {
    return this.state.page ? (
      <Elements stripe={this.state.stripePromise}>
        <App {...this.state.page} page_id={this.props.match.params.id} />
      </Elements>
    ) : (
      <div />
    );
  }
}

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/:id" component={MainApp} />
      <Route component={MainApp} />
    </Switch>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
