import { createMoneyIntlFormatter } from "@easymoney/formatter";
import { createMoney } from "@easymoney/money";
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentMethod, PaymentRequest, StripeCardElement } from "@stripe/stripe-js";
import { getCodeList } from "country-list";
import React, { useCallback, useEffect, useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { useForm } from "react-hook-form";
import { Link, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import "unfetch/polyfill";
import { STRIPE_PAYMENT_INTENT_SECRET_URL } from "./const";
import {
  LoadingDiv,
  PrimaryButton,
  PrimaryInput,
  PrimaryInputAddon,
  PrimaryInputDiv,
  PrimaryInputLabel,
  PrimarySelect,
} from "./utils/StyledComponents";

interface DonationFormData {
  amount: number;
  name: string;
  email: string;
  pay_by: string;
  address: {
    city: string;
    country: string;
    line1: string;
    postal_code: string;
    state: string;
  };
}

export interface DonationFormProps {
  page_id: string;
  brandColor: string;
  embedded?: boolean;
  campus_obj: {
    name: string;
    currency: string;
    country: string;
    causes: { name: string; kweeve_id: string }[];
    organisation_obj: {
      payment_gateway: { slug: string; capabilities: { acss_debit_payments?: string } };
    };
  };
}

const useQuery = () => new URLSearchParams(useLocation().search);

// TODO: ERROR MESSAGES WHILE PROCESSING PAYMENT

export const DonationForm = ({
  brandColor,
  campus_obj,
  page_id,
  embedded = false,
}: DonationFormProps) => {
  let { pathname, search: query_string } = useLocation();
  let { path, url } = useRouteMatch();
  let history = useHistory();
  let query = useQuery();
  const { register, handleSubmit, errors, trigger, watch, setValue } = useForm<DonationFormData>();

  const stripe_pi = query.get("stripe_pi");
  const stripe_si = query.get("stripe_si");

  const [recurringSchedule, changeRecurringSchedule] = useState<string>();
  const [fundSelected, changeFundSelected] =
    useState<DonationFormProps["campus_obj"]["causes"][0]>();
  const [amountSelected, changeAmountSelected] = useState(parseInt(query.get("amount") || "1000"));
  const [paymentProcessing, togglePaymentProcessing] = useState(false);
  const [paymentSuccessful, togglePaymentSuccessful] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>();
  const stripe = useStripe();
  const elements = useElements();
  const pay_by_opt = watch("pay_by", "card");
  const watchAmount = watch("amount", amountSelected / 100.0);

  const amountSelectedDisplay = createMoneyIntlFormatter().format(
    createMoney({ amount: amountSelected, currency: campus_obj.currency }),
    undefined,
    { useGrouping: true }
  );

  const setRecurringSchedule = (schedule?: string) => {
    setPaymentRequest(undefined);
    changeRecurringSchedule(schedule);
  };

  const setFundSelected = (fund?: DonationFormProps["campus_obj"]["causes"][0]) => {
    setPaymentRequest(undefined);
    changeFundSelected(fund);
  };

  const validAmount = (amount: number) => (amount * 100) % 1 === 0;

  const processPayment = useCallback(
    async (
      paymentMethod:
        | PaymentMethod
        | {
            card?: StripeCardElement;
            billing_details: Omit<Omit<DonationFormData, "amount">, "pay_by">;
          },
      onFail: () => void,
      onSuccess: () => void,
      acss = false
    ) => {
      if (!stripe) {
        console.error("[kweeve.processPayment] Stripe has not been setup");
        return;
      }

      let error_message;
      let client_secret;
      const stripeConfirm = stripe_si
        ? acss
          ? stripe.confirmAcssDebitSetup
          : stripe.confirmCardSetup
        : acss
        ? stripe.confirmAcssDebitPayment
        : stripe.confirmCardPayment;
      togglePaymentProcessing(true);

      if (stripe_si || stripe_pi) {
        const response = await fetch(
          `${STRIPE_PAYMENT_INTENT_SECRET_URL}/${stripe_si || stripe_pi}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              page_id,
              amount: amountSelected,
            }),
          }
        );
        const response_json = await response.json();
        client_secret = response_json.client_secret;
        error_message = response_json.error_message;
      } else {
        const response = await fetch(STRIPE_PAYMENT_INTENT_SECRET_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...paymentMethod.billing_details,
            page_id,
            rate: recurringSchedule,
            amount: amountSelected,
            cause_id: fundSelected?.kweeve_id,
          }),
        });
        const response_json = await response.json();
        client_secret = response_json.client_secret;
        error_message = response_json.error_message;
      }

      if (error_message || !client_secret) {
        setPaymentErrorMessage(error_message || "Error while processing. Please try again");
        console.error(
          "[kweeve.payment.request] There was an error while talking to backend",
          error_message
        );
        onFail();
        togglePaymentProcessing(false);
        return;
      }

      const confirmOpts = acss ? {} : { handleActions: false };
      const { error: confirmError } = await stripeConfirm(
        client_secret,
        {
          payment_method: (paymentMethod as PaymentMethod).id
            ? (paymentMethod as PaymentMethod).id
            : (paymentMethod as {
                card: StripeCardElement;
                billing_details: Omit<Omit<DonationFormData, "amount">, "pay_by">;
              }),
        },
        confirmOpts
      );

      if (confirmError) {
        setPaymentErrorMessage(confirmError.message);
        console.error(
          "[kweeve.payment.request] There was an error from stripe while confirming request. stripe_error_code = ",
          confirmError.code
        );
        onFail();
        togglePaymentProcessing(false);
      } else {
        onSuccess();
        const { error } = acss ? { error: null } : await stripeConfirm(client_secret);
        if (error) {
          setPaymentErrorMessage(error.message);
          console.error(
            "[kweeve.payment.request] There was an error from stripe while completing request. stripe_error_code = ",
            error.code
          );
          togglePaymentProcessing(false);
        } else {
          togglePaymentProcessing(false);
          togglePaymentSuccessful(true);
        }
      }
    },
    [amountSelected, fundSelected, page_id, recurringSchedule, stripe, stripe_pi, stripe_si]
  );

  useEffect(() => {
    setPaymentRequest(undefined);
    const floatAmount = parseFloat(String(watchAmount));
    if (floatAmount > 0 && floatAmount <= 999999.99)
      changeAmountSelected(Math.floor(floatAmount * 100));
  }, [watchAmount]);

  useEffect(() => {
    if (stripe && amountSelected) {
      const pr = stripe.paymentRequest({
        country: campus_obj.country.toUpperCase(),
        currency: campus_obj.currency.toLowerCase(),
        total: {
          label:
            stripe_si !== null
              ? "Add Payment Method"
              : `${recurringSchedule ? recurringSchedule.toUpperCase() : ""} Donation to ${
                  campus_obj.name
                } ${fundSelected ? fundSelected?.name.toUpperCase() : ""}`.trim(),
          amount: stripe_si !== null ? 100 : amountSelected,
          pending: stripe_si !== null,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.on("paymentmethod", async event => {
        processPayment(
          event.paymentMethod,
          () => event.complete("fail"),
          () => {
            setPaymentMethod(event.paymentMethod);
            event.complete("success");
          }
        );
      });

      pr.canMakePayment().then((result: any) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [
    stripe,
    amountSelected,
    recurringSchedule,
    fundSelected,
    page_id,
    campus_obj.country,
    campus_obj.currency,
    campus_obj.name,
    stripe_si,
    processPayment,
    pathname,
  ]);

  const onSubmit = async ({ name, email, address, pay_by }: DonationFormData) => {
    if (url === pathname && !paymentMethod) {
      history.push(`${url}/payment`);
    } else {
      const cardElement = elements?.getElement(CardElement) || undefined;
      if (pay_by === "card" && !cardElement) {
        console.error("[kweeve.onSubmit] Card element not found");
        return;
      }

      processPayment(
        {
          card: cardElement,
          billing_details: {
            name,
            email,
            address,
          },
        },
        () => {},
        () => {},
        pay_by === "acss"
      );
    }
  };

  const PaymentRequestButton = paymentRequest && (
    <PaymentRequestButtonElement
      onClick={async event => {
        if (paymentMethod) {
          event.preventDefault();
          setPaymentErrorMessage(
            "You can only use the PaymentRequest button once. Refresh the page to start over."
          );
        } else if (!(await trigger(["amount", "fund", "frequency"]))) {
          event.preventDefault();
        }
      }}
      options={{
        style: {
          paymentRequestButton: {
            type: stripe_si ? "default" : "donate",
            theme: "dark",
            height: "46px",
          },
        },
        paymentRequest,
      }}
    />
  );
  const countryOptions = [];
  const countryCodeList = getCodeList();
  for (const key in countryCodeList) {
    countryOptions.push(
      <option key={key} value={key.toUpperCase()}>
        {countryCodeList[key]}
      </option>
    );
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`card border-0 m-auto ${!embedded && "shadow-lg"}`}
        style={{ maxWidth: "400px" }}
      >
        <div
          className="container-fluid card-header"
          style={{
            backgroundColor: brandColor,
          }}
        >
          <div className="row align-items-center">
            <div className="col-auto">
              {query_string || paymentProcessing || paymentSuccessful || url === pathname ? (
                <span className="text-light text-muted">
                  <ArrowLeft />
                </span>
              ) : (
                <Link className="text-light text-decoration-none btn btn-link p-0" to={url}>
                  <ArrowLeft />
                </Link>
              )}
            </div>
            <div className="col text-center">
              <h1 className="m-0 text-white font-weight-bolder h4">
                {!embedded && campus_obj.name}
              </h1>
            </div>
            <div className="col-auto">
              {query_string || paymentProcessing || paymentSuccessful || pathname !== url ? (
                <span className="text-light text-muted">
                  <ArrowRight />
                </span>
              ) : (
                <button type="submit" className="text-light text-decoration-none btn btn-link p-0">
                  <ArrowRight />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="card-body position-relative">
          <Switch>
            <Route path={`${path}`} exact>
              <h1 className="card-title h5 text-uppercase">Donation</h1>

              <div className="form-row">
                <div className="col-md">
                  <div className="form-group">
                    <label className="text-muted">Give</label>
                    <div
                      className="d-flex flex-wrap flex-column justify-content-between mb-3"
                      style={{ height: "65px" }}
                    >
                      {[1000, 10000, 5000, 25000].map(number => (
                        <div
                          key={number}
                          style={{ zIndex: 0 }}
                          className="custom-control custom-radio"
                        >
                          <input
                            className="custom-control-input"
                            id={`give-${number}`}
                            name="amount"
                            type="radio"
                            value={number}
                            checked={number / 100.0 === watchAmount / 1.0}
                            onChange={() => setValue("amount", number / 100.0)}
                          />
                          <PrimaryInputLabel
                            color={brandColor}
                            className="custom-control-label"
                            htmlFor={`give-${number}`}
                          >
                            {createMoneyIntlFormatter().format(
                              createMoney({
                                amount: number,
                                currency: campus_obj.currency,
                              }),
                              undefined,
                              { currencyDisplay: "code", useGrouping: true }
                            )}
                          </PrimaryInputLabel>
                        </div>
                      ))}
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <PrimaryInputAddon className="input-group-prepend" color={brandColor}>
                          <span className="input-group-text">{campus_obj.currency}</span>
                        </PrimaryInputAddon>
                        <PrimaryInput
                          name="amount"
                          ref={register({
                            required: true,
                            min: 1,
                            max: 999999.99,
                            validate: validAmount,
                          })}
                          color={brandColor}
                          type="number"
                          className={`form-control border-dark ${errors.amount && "is-invalid"}`}
                          placeholder="Amount"
                          defaultValue={amountSelected / 100.0}
                          step="any"
                        />
                      </div>
                      {errors.amount && errors.amount.type === "required" && (
                        <div className="d-block invalid-feedback">Please enter an amount.</div>
                      )}
                      {errors.amount && errors.amount.type === "validate" && (
                        <div className="d-block invalid-feedback">
                          Please round to nearest tenth.
                        </div>
                      )}
                      {errors.amount && errors.amount.type === "min" && (
                        <div className="d-block invalid-feedback">Amount cant be less than 1.</div>
                      )}
                      {errors.amount && errors.amount.type === "max" && (
                        <div className="d-block invalid-feedback">
                          Donation amount is too large.
                        </div>
                      )}
                    </div>
                  </div>
                  {campus_obj.causes.length > 0 && (
                    <div className="form-group">
                      <label className="text-muted" htmlFor="select-designation">
                        Fund
                      </label>
                      <PrimarySelect
                        name="fund"
                        color={brandColor}
                        className="custom-select border-dark"
                        id="select-designation"
                        ref={register({ required: true })}
                        value={fundSelected?.kweeve_id}
                        onChange={e =>
                          setFundSelected(
                            campus_obj.causes.find(cause => cause.kweeve_id === e.target.value)
                          )
                        }
                      >
                        {campus_obj.causes.map(cause => (
                          <option key={cause.kweeve_id} value={cause.kweeve_id}>
                            {cause.name}
                          </option>
                        ))}
                      </PrimarySelect>
                    </div>
                  )}

                  <div className="form-group">
                    <label className="text-muted">Frequency</label>
                    <div
                      className="d-flex flex-column flex-wrap justify-content-between"
                      style={{ height: "65px" }}
                    >
                      <div style={{ zIndex: 0 }} className="custom-control custom-radio">
                        <input
                          className="custom-control-input"
                          id="give-once"
                          name="frequency"
                          type="radio"
                          value={undefined}
                          checked={!recurringSchedule}
                          onChange={e => setRecurringSchedule(undefined)}
                          ref={register({ required: true })}
                        />
                        <PrimaryInputLabel
                          color={brandColor}
                          className="custom-control-label"
                          htmlFor="give-once"
                        >
                          Once
                        </PrimaryInputLabel>
                      </div>
                      <div style={{ zIndex: 0 }} className="custom-control custom-radio">
                        <input
                          className="custom-control-input"
                          id="give-biweekly"
                          name="frequency"
                          type="radio"
                          value="biweekly"
                          checked={"biweekly" === recurringSchedule}
                          onChange={e => setRecurringSchedule(e.target.value)}
                          ref={register({ required: true })}
                        />
                        <PrimaryInputLabel
                          color={brandColor}
                          className="custom-control-label"
                          htmlFor="give-biweekly"
                        >
                          Biweekly
                        </PrimaryInputLabel>
                      </div>
                      <div style={{ zIndex: 0 }} className="custom-control custom-radio">
                        <input
                          className="custom-control-input"
                          id="give-weekly"
                          name="frequency"
                          type="radio"
                          value="weekly"
                          checked={"weekly" === recurringSchedule}
                          onChange={e => setRecurringSchedule(e.target.value)}
                          ref={register({ required: true })}
                        />
                        <PrimaryInputLabel
                          color={brandColor}
                          className="custom-control-label"
                          htmlFor="give-weekly"
                        >
                          Weekly
                        </PrimaryInputLabel>
                      </div>
                      <div style={{ zIndex: 0 }} className="custom-control custom-radio">
                        <input
                          className="custom-control-input"
                          id="give-monthly"
                          name="frequency"
                          type="radio"
                          value="monthly"
                          checked={"monthly" === recurringSchedule}
                          onChange={e => setRecurringSchedule(e.target.value)}
                          ref={register({ required: true })}
                        />
                        <PrimaryInputLabel
                          color={brandColor}
                          className="custom-control-label"
                          htmlFor="give-monthly"
                        >
                          Monthly
                        </PrimaryInputLabel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Route>
            <Route path={`${path}/payment`} exact>
              <>
                <h1 className="card-title h5 text-uppercase">Payment Information</h1>
                {!!PaymentRequestButton && (
                  <>
                    {PaymentRequestButton}
                    <div className="d-flex my-3 text-center text-muted">
                      <hr className="flex-grow-1" />
                      <span className="align-self-center px-3">Or enter payment details below</span>
                      <hr className="flex-grow-1" />
                    </div>
                  </>
                )}
                <div className="form-row">
                  <div className="col-md">
                    <div className="form-group">
                      <PrimaryInput
                        name="email"
                        type="email"
                        color={brandColor}
                        placeholder="Email"
                        className={`border-dark form-control ${errors.email && "is-invalid"}`}
                        ref={register({ required: true })}
                      />
                      {errors.email && (
                        <div className="d-block invalid-feedback">Email is required.</div>
                      )}
                    </div>
                    <div className="form-group">
                      <PrimaryInput
                        name="name"
                        type="text"
                        color={brandColor}
                        placeholder="Name"
                        className={`border-dark form-control ${errors.name && "is-invalid"}`}
                        ref={register({ required: true })}
                      />
                      {errors.name && (
                        <div className="d-block invalid-feedback">Name is required.</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="text-muted">Pay By</label>
                      <div className="d-flex flex-wrap mb-2">
                        <div style={{ zIndex: 0 }} className="custom-control custom-radio mr-3">
                          <input
                            className="custom-control-input"
                            id="card"
                            value="card"
                            name="pay_by"
                            type="radio"
                            ref={register({ required: true })}
                            defaultChecked={pay_by_opt === "card"}
                          />
                          <PrimaryInputLabel
                            color={brandColor}
                            className="custom-control-label"
                            htmlFor="card"
                          >
                            Card
                          </PrimaryInputLabel>
                        </div>
                        {campus_obj.organisation_obj.payment_gateway.capabilities
                          .acss_debit_payments === "active" && (
                          <div style={{ zIndex: 0 }} className="custom-control custom-radio">
                            <input
                              className="custom-control-input"
                              id="acss"
                              value="acss"
                              name="pay_by"
                              type="radio"
                              ref={register({ required: true })}
                              defaultChecked={pay_by_opt === "acss"}
                            />
                            <PrimaryInputLabel
                              color={brandColor}
                              className="custom-control-label"
                              htmlFor="acss"
                            >
                              Pre-Authorized Debit
                            </PrimaryInputLabel>
                          </div>
                        )}
                      </div>
                      {pay_by_opt === "card" && (
                        <PrimaryInputDiv
                          color={brandColor}
                          className="form-control border-dark d-flex flex-column justify-content-center"
                        >
                          <CardElement />
                        </PrimaryInputDiv>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-md">
                    <div className="form-group">
                      <PrimaryInput
                        name="address.line1"
                        type="text"
                        color={brandColor}
                        placeholder="Street Address"
                        className={`border-dark form-control ${
                          errors.address?.line1 && "is-invalid"
                        }`}
                        ref={register({ required: true })}
                      />
                      {errors.address?.line1 && (
                        <div className="d-block invalid-feedback">Address is required.</div>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <PrimaryInput
                          name="address.city"
                          color={brandColor}
                          placeholder="City"
                          className={`border-dark form-control ${
                            errors.address?.city && "is-invalid"
                          }`}
                          ref={register({ required: true })}
                        />
                        <PrimaryInput
                          name="address.state"
                          color={brandColor}
                          placeholder="State"
                          className={`border-dark form-control ${
                            errors.address?.state && "is-invalid"
                          }`}
                          ref={register({ required: true })}
                        />
                        <PrimarySelect
                          name="address.country"
                          color={brandColor}
                          className={`custom-select border-dark form-control ${
                            errors.address?.country && "is-invalid"
                          }`}
                          ref={register({ required: true })}
                          defaultValue={campus_obj.country.toUpperCase()}
                        >
                          {countryOptions}
                        </PrimarySelect>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col p-0">
                            {errors.address?.city && (
                              <div className="d-block invalid-feedback">City is required.</div>
                            )}
                          </div>
                          <div className="col p-0">
                            {errors.address?.state && (
                              <div className="d-block invalid-feedback">state is required.</div>
                            )}
                          </div>
                          <div className="col p-0">
                            {errors.address?.country && (
                              <div className="d-block invalid-feedback">Country is required.</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </Route>
          </Switch>
          {(paymentProcessing || paymentSuccessful) && (
            <div className="d-flex flex-column justify-content-around position-absolute bg-white top-right-bottom-left-0">
              <LoadingDiv
                className={`m-auto circle-loader ${paymentSuccessful && "load-complete"}`}
                color={brandColor}
              >
                {paymentSuccessful && <div className="check-mark draw" />}
              </LoadingDiv>
            </div>
          )}
        </div>

        <div className="card-footer text-center border-0 bg-transparent">
          {paymentSuccessful ? (
            <p style={{ color: brandColor }}>
              {stripe_si ? "Successfully Added Payment Method" : "Donation was successful"}
            </p>
          ) : paymentProcessing ? (
            <p style={{ color: brandColor }}>
              {stripe_si ? "Adding Payment Method..." : "Donation Processing ..."}
            </p>
          ) : pathname === `${url}/3d_auth` ? (
            <div className="d-block">Payment authentication completed</div>
          ) : (
            <>
              <PrimaryButton
                color={brandColor}
                type="submit"
                className="btn btn-dark btn-lg"
                style={{ width: "250px" }}
              >
                {pathname === url
                  ? "Next"
                  : stripe_si !== null
                  ? "Add Payment Method"
                  : `Donate ${amountSelectedDisplay} ${
                      recurringSchedule ? recurringSchedule : ""
                    }`.trim()}
              </PrimaryButton>

              {paymentErrorMessage && (
                <div className="d-block invalid-feedback">{paymentErrorMessage}</div>
              )}
            </>
          )}
        </div>
      </form>
      <a
        href="https://kweeve.com?ref=powered_by_kweeve"
        className="d-block m-3 small text-center text-dark"
        color={brandColor}
        rel="noopener noreferrer"
        target="_blank"
      >
        Powered by Kweeve
      </a>
    </>
  );
};
