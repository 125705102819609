import styled from '@emotion/styled';

interface PrimaryProps {
  color?: string;
}

export const PrimaryButton = styled.button < PrimaryProps > `
  border: none;
  color: white;
  background-color: ${({ color }) => color} !important;
  box-shadow: 0 0 5px 0 white;
  &:hover {
    box-shadow: 1px 1px 8px 0 black;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
    border: none;
  }
`;

export const PrimaryInputAddon = styled.div < PrimaryProps > `
  & span {
    border-color: ${({ color }) => color} !important;
    background-color: ${({ color }) => color} !important;
    color: white !important;
  }
`;
export const PrimaryOutlineButton = styled.button < PrimaryProps > `
  color: ${({ color }) => color} !important;
  border-color: ${({ color }) => color} !important;
  &:hover {
    background-color: ${({ color }) => color} !important;
    color: white !important;
    box-shadow: 1px 1px 8px 0 black !important;
  }
  &:focus,
  &.active {
    background-color: ${({ color }) => color} !important;
    color: white !important;
  }
`;

export const PrimaryInput = styled.input < PrimaryProps > `
  border-color: ${({ color }) => color} !important;
`;

export const PrimaryInputLabel = styled.label < PrimaryProps > `
  input:checked ~ &::before {
    border-color: ${({ color }) => color} !important;
    background-color: ${({ color }) => color} !important;
  }
`;

export const PrimaryInputDiv = styled.div < PrimaryProps > `
  border-color: ${({ color }) => color} !important;
`;

export const PrimarySelect = styled.select < PrimaryProps > `
  border-color: ${({ color }) => color} !important;
`;

export const LoadingDiv = styled.div < PrimaryProps > `
  &.circle-loader {
    border: 1em solid rgba(0, 0, 0, 0.2);
    border-left-color: ${({ color }) => color};
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: 12em;
    height: 12em;
  }

  &.load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: ${({ color }) => color};
    transition: border 500ms ease-out;
  }

  & .check-mark::after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: check-mark;
    transform: scaleX(-1) rotate(135deg);
    opacity: 1;
    height: 6em;
    width: 3em;
    transform-origin: left top;
    border-right: 1em solid ${({ color }) => color};
    border-top: 1em solid ${({ color }) => color};
    content: "";
    left: 2em;
    top: 5.5em;
    position: absolute;
  }

  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes check-mark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 3em;
      opacity: 1;
    }
    40% {
      height: 6em;
      width: 3em;
      opacity: 1;
    }
    100% {
      height: 6em;
      width: 3em;
      opacity: 1;
    }
  }

  @-webkit-keyframes check-mark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 3em;
      opacity: 1;
    }
    40% {
      height: 6em;
      width: 3em;
      opacity: 1;
    }
    100% {
      height: 6em;
      width: 3em;
      opacity: 1;
    }
  }
`;
